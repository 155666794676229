import React from "react"
import Seo from "../components/SEO"

const ThankyouPage = () => (
  <>
    <Seo
      title="Thank you!"
      description="We received your Pet Planner Request. "
    />
    <h1>Thank you!</h1>
    <p>
      We received your Pet Planner Request. You should receive your free gift in
      2 weeks.
    </p>
  </>
)

export default ThankyouPage
